<template>
    <div class="home">
        <div class="tab__menu">
            <div class="tab__title">{{tabTitle}}</div>
            <el-button style="float: right; margin-top: -40px;" type="primary" @click="handleEditClick">編輯</el-button>
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="代表案例" name="0"></el-tab-pane>
                <el-tab-pane label="专业研究" name="1"></el-tab-pane>
                <el-tab-pane label="相关动态" name="2"></el-tab-pane>
                <el-tab-pane label="代表律师" name="3"></el-tab-pane>
            </el-tabs>
        </div>
        <!-- 相关动态 -->
        <template v-if="showNews"> 
            <div class="tablewrapper">
                <el-table :data="currentList" tooltip-effect="dark" stripe>
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="typeZh" label="类型" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="title" label="文章标题" min-width="100" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="peopleZh" label="律师关联" min-width="40" align="center"></el-table-column>
                    <el-table-column prop="relateZh" label="案例关联" min-width="40" align="center"></el-table-column>
                    <el-table-column prop="displayZh" label="展示状态" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
        </template>
        <!-- 案例关联 -->
        <el-dialog :title="dialogTitle" v-model="caseVisible" width="60%">
            <div style="text-align: center; margin-top: -30px;">
                   <el-radio-group v-model="caseType" @change="caseTypeChange">
                    <el-radio-button label="0">所有类型</el-radio-button>
                    <el-radio-button label="1">元诺动态</el-radio-button>
                    <el-radio-button label="2">元诺案例</el-radio-button>
                    <el-radio-button label="3">聚焦观察</el-radio-button>
                    <el-radio-button label="4">社会责任</el-radio-button>
                    <el-radio-button label="5">已经关联（<b>{{ caseSelects.length }}</b>）个</el-radio-button>
                </el-radio-group>
            </div>
            <!-- 案例选择 -->
           <div class="tablewrapper" style="margin-top: 10px;">
                <el-table ref="multipleCaseTable" :data="caseList" tooltip-effect="dark" @selection-change="handleCaseChange" stripe height="500">
                    <el-table-column type="selection" min-width="40"></el-table-column>
                    <el-table-column type="index" min-width="40" label="#"></el-table-column>
                    <el-table-column prop="title" min-width="140" label="文章标题" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="peopleZh" label="律师关联" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="relateZh" label="案例关联" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>

            <div style="text-align: center; margin-top: 25px; ">
                <el-button @click="caseVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handleCaseSure" style="width: 100px; margin-left: 40px;">保存</el-button>
            </div>
        </el-dialog>
        <!-- 相关律师 -->
        <template v-if="!showNews"> 
            <div class="tablewrapper">
                <el-table :data="currentList" tooltip-effect="dark" stripe>
                    <el-table-column type="index" width="40" label="#"></el-table-column>
                    <el-table-column prop="name" label="姓名" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="title" label="职称" min-width="60" align="center"></el-table-column>
                    <el-table-column prop="phone" label="电话" min-width="100" align="center"></el-table-column>
                    <el-table-column prop="field" label="擅长领域" min-width="100" align="center" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="displayZh" label="展示状态" min-width="50" align="center"></el-table-column>
                    <el-table-column prop="updatedAt" label="更新时间" :formatter="formatter" min-width="90" align="center" show-overflow-tooltip></el-table-column>
                </el-table>
            </div>
        </template>
        <!-- 代表律师 -->
        <el-dialog title="代表律师" v-model="lawyerVisible">
            <div style="text-align: center">
                <el-transfer style="text-align: left; display: inline-block;" 
                :button-texts="['移除', '添加']"
                :data="peopleList" v-model="peopleSelects"
                @change="handleManagePeople"
                filterable
                :titles="['未关联律师','已关联律师']"></el-transfer>
            </div>
            <div class="dialog-footer" style="margin-top: 30px;">
                <el-button @click="lawyerVisible = false" style="width: 100px;">取消</el-button>
                <el-button type="primary" @click="handlePeopleSure" :loading="loadingOne" style="width: 100px; margin-left: 40px;">保存</el-button>
                <div class="tips">tips：①先勾选。②再移除或添加。③最后取消或保存。</div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
const Util = require('@/assets/utils');

import {
    getBuilding as get,
    updateBuilding as update,
    getPeopleField as getPeople,
    updatePeopleField as updatePeople,

    getBuildingCase as allCase,
    updateBuildingCase as updateCase,

} from "@/api/api";
const code = 200

  export default {
    data() {
      return {
        activeName: '0',
        tabTitle: '代表案例',
        currentList: [],
        lists: [],
        showNews: true,
        lawyerVisible: false,
        peopleList: [],
        peopleSelects: [],
        handldId: 0,
        loadingOne: false,

        caseVisible: false,
        caseType: 0,
        caseAllList: [],
        caseList:[],
        caseSelects: [],
        caseSelectIds: [],
        handleCaseId: 0,
        dialogTitle: ''
      };
    },
    methods: {
        // 时间格式化
        formatter(row,column){
            return Util.fmt(row.updatedAt)
        },
        handleClick(tab, event) {
            this.showNews = tab.index == '3' ? false : true
            this.tabTitle = this.activeName == '0' ? '代表案例':(this.activeName == '1' ? '专业研究' : (this.activeName == '2' ? '相关动态' : '代表律师'))
            this.currentList = this.lists[this.activeName]
        },
        handleEditClick(){
            // 代表律师
            if(this.activeName == '3'){
                this.peopleList = []
                getPeople({id: this.handldId}).then(res =>{
                    console.log('律师关联 === ', res)
                    if(res.data.code == code){
                        this.lawyerVisible = true
                        var list = res.data.data.all
                        for(var i = 0; i < list.length; i ++){
                            var obj = {
                                key: list[i].id,
                                label: list[i].name
                            }
                            this.peopleList.push(obj)
                        }
                        this.peopleSelects = res.data.data.selects
                    }
                }).catch(() =>{
                    this.$message({
                        type: 'error',
                        message:"查询失败,请重试或者联系管理员！！"
                    })
                })
            }else{
                this.handleCaseId = this.handldId
                this.caseVisible = true
                this.dialogTitle = this.activeName == '0' ? '案例关联' : (this.activeName == '1' ? '专业研究':'相关动态')
                this.caseType = 0
                allCase({id: this.handldId, type: this.activeName}).then(res =>{
                    if(res.data.code == code){
                        this.caseAllList = res.data.data.all
                        this.caseList = this.caseAllList[0]
                        this.caseSelects = res.data.data.selects
                        this.caseSelectIds = res.data.data.selectIds
                        this.caseTypeChange()
                    }else{
                        this.$message({
                            type: 'error',
                            message: res.data.msg
                        })
                    }
                }).catch(() =>{
                    this.$message({
                        type: 'error',
                        message:"更新失败,请重试或者联系管理员！！"
                    })
                })
            }
        },
        handleManagePeople(){
            console.log(this.peopleSelects)
        },
        handlePeopleSure(){
            updatePeople({id: this.handldId, people: this.peopleSelects.join(',')}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                    this.lawyerVisible = false,
                    this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"更新失败,请重试或者联系管理员！！"
                })
            })
        },
        caseTypeChange(){
            // this.$refs.multipleCaseTable.toggleRowSelection(0, true)
            if(this.caseType != '5'){
                this.caseList = this.caseAllList[this.caseType]
                if(this.caseList.length && this.caseSelectIds.length){
                    this.caseList.forEach((row) =>{
                        if(this.caseSelectIds.indexOf(row.id) >= 0){
                            this.$nextTick(function(){
                                this.$refs.multipleCaseTable.toggleRowSelection(row, true)
                            })
                            console.log('111 === ',row.id)
                        }
                        console.log('222 === ',row.id)
                    })
                }
            }else{
                this.caseList = this.caseSelects
                if(this.caseList.length){
                    this.$refs.multipleCaseTable.toggleAllSelection()
                }
            }
        },
        handleCaseChange(values){
            if(this.caseType == 0 || this.caseType == 5){
                this.caseSelectIds = []
                this.caseSelects = values
                values.forEach(item =>{
                    this.caseSelectIds.push(item.id)
                })
                console.log(this.caseSelectIds, this.caseSelects)
            }else{
                // 先删除对应type的所有数据
                var newArray = []
                for(var i = 0; i < this.caseSelects.length; i ++){
                    if(this.caseSelects[i].type != this.caseType){
                        newArray.push(this.caseSelects[i])
                    }
                }
                if(values.length){
                    newArray = newArray.concat(values)
                }
                this.caseSelects = newArray
                this.caseSelectIds = newArray.map(item => {return item.id})
                console.log('length === ', this.caseSelectIds.length, this.caseSelects.length, values.length)
            }
        },
        handleCaseSure(){
            updateCase({id: this.handleCaseId,type: this.activeName, relate: this.caseSelectIds.toString()}).then(res =>{
                if(res.data.code == code){
                    this.$message({
                        message: res.data.msg,
                        type: "success"
                    });
                    this.caseVisible = false,
                    this.getList()
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"更新失败,请重试或者联系管理员！！"
                })
            })
            console.log(this.caseSelectIds.toString())
        },
        getList(){
            get(this.query).then(res =>{
                console.log(res)
                if(res.data.code == code){
                    this.lists = res.data.data.list
                    this.handldId = res.data.data.id
                    this.currentList = this.lists[this.activeName]
                }else{
                    this.$message({
                        type: 'error',
                        message: res.data.msg
                    })
                }
            }).catch(() =>{
                this.$message({
                    type: 'error',
                    message:"查询失败,请重试或者联系管理员！！"
                })
            })
        },

    },
    mounted(){
        this.getList()
    },
  };
</script>
<style lang="less">
// .tab__menu{
//     display: inline-block;
// }
.tab__title{
    line-height: 40px; 
    text-align: center; 
    font-size: 20px;
    color: rgb(104, 102, 102);
}
.tab__item{
    line-height: 40px;
    text-align: center;
    background-color: red;
}
.dialog-footer{
    text-align: center;
}
.dialog-footer .tips{
    line-height: 40px;
    color: red;
    /* background-color: yellow; */
    font-size: 10px;
    // text-align: right;
}
</style>